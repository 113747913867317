<template>
  <div class="addGoodsInfo" ref="addGoodsInfo">
    <div class="left">
      <!-- 基本资讯 -->
      <div class="cell">
        <div class="title" ref="title1">基本资讯</div>
        <div class="container">
          <div class="label">商品图片</div>
          <div class="content">
            <div class="item">
              <UploadFiles1 :imageUrl="formData.basicInfo.imageUrl1" :ids="formData.basicInfo.ids1"></UploadFiles1>
              <div class="name">* 封面照片</div>
            </div>
            <div class="item">
              <UploadFiles1 :imageUrl="formData.basicInfo.imageUrl2[0]" :ids="formData.basicInfo.ids2[0]"></UploadFiles1>
              <div class="name names">图片 1</div>
            </div>
            <div class="item">
              <UploadFiles1 :imageUrl="formData.basicInfo.imageUrl2[1]" :ids="formData.basicInfo.ids2[1]"></UploadFiles1>
              <div class="name names">图片 2</div>
            </div>
            <div class="item">
              <UploadFiles1 :imageUrl="formData.basicInfo.imageUrl2[2]" :ids="formData.basicInfo.ids2[2]"></UploadFiles1>
              <div class="name names">图片 3</div>
            </div>
            <div class="item">
              <UploadFiles1 :imageUrl="formData.basicInfo.imageUrl2[3]" :ids="formData.basicInfo.ids2[3]"></UploadFiles1>
              <div class="name names">图片 4</div>
            </div>
            <div class="item">
              <UploadFiles1 :imageUrl="formData.basicInfo.imageUrl2[4]" :ids="formData.basicInfo.ids2[4]"></UploadFiles1>
              <div class="name names">图片 5</div>
            </div>
            <div class="item">
              <UploadFiles1 :imageUrl="formData.basicInfo.imageUrl2[5]" :ids="formData.basicInfo.ids2[5]"></UploadFiles1>
              <div class="name names">图片 6</div>
            </div>
            <div class="item">
              <UploadFiles1 :imageUrl="formData.basicInfo.imageUrl2[6]" :ids="formData.basicInfo.ids2[6]"></UploadFiles1>
              <div class="name names">图片 7</div>
            </div>
            <div class="item">
              <UploadFiles1 :imageUrl="formData.basicInfo.imageUrl2[7]" :ids="formData.basicInfo.ids2[7]"></UploadFiles1>
              <div class="name names">图片 8</div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="label">商品视频</div>
          <div class="content">
            <div class="item">
              <UploadVideo1 :imageUrl="formData.basicInfo.goods_video_cover" :video="formData.basicInfo.goods_video_data"
                :ids="formData.basicInfo.goods_video" :accept="accept"></UploadVideo1>
              <div class="name">封面视频</div>
            </div>
            <div class="text">
              1.大小：最大30Mb,像素不可超过1280x1280px
              <br />
              2.时长：10s-60s
              <br />
              3.格式：支持MP4
              <br />
              4.注意：您可以在影片处理过程中上架此商品。一旦处理完成，影片将显示在商
              <br />
              品页面。
            </div>
          </div>
        </div>
        <div class="container">
          <div class="label">*商品名称</div>
          <div class="content">
            <el-input v-model="formData.basicInfo.title" maxlength="180" show-word-limit placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="container">
          <div class="label">*商品描述</div>
          <div class="content" style="z-index: 99">
            <el-input v-model="formData.basicInfo.description" type="textarea" show-word-limit maxlength="5000" :autosize="{
              minRows: 8,
              maxRows: 20
            }" placeholder="请输入"></el-input>
            <!-- <div style="border: 1px solid #ccc; width: 100%">
              <Toolbar
                :editor="editor"
                :mode="mode"
                :defaultConfig="toolbarConfig"
              />
              <Editor
                style="height: 300px"
                v-model="formData.basicInfo.description"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              />
            </div> -->
          </div>
        </div>
        <div class="container">
          <div class="label">*类别</div>
          <div class="content">
            <el-input :disabled="$route.query.type == 1" @focus="handleOpenPop" readonly
              v-model="formData.basicInfo.category_path" placeholder="请选择分类"> <i class="el-icon-edit el-input__icon"
                slot="suffix">
              </i></el-input>
          </div>
        </div>
      </div>
      <!-- 商品属性 -->
      <div class="cell1">
        <div class="title" ref="title2">商品属性</div>
        <div style="font-size: 14px; margin-top: 10px; margin-bottom: 40px">
          填写更多且准确的属性以提高产品的曝光率。
        </div>
        <div class="container">
          <div style="width: 50%; display: flex">
            <div class="label" style="padding-top: 10px">*品牌</div>
            <!-- <el-input
              style="width: 60%"
              v-model="formData.category_attribute[0].value"
              placeholder="请输入"
            ></el-input> -->
            <el-select style="width: 60%" v-model="formData.category_attribute[0].value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </div>
          <div style="width: 50%; display: flex">
            <div class="label" style="padding-top: 10px">*材质</div>
            <el-input style="width: 60%" v-model="formData.category_attribute[1].value" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="container">
          <div style="width: 50%; display: flex">
            <div class="label" style="padding-top: 10px">原产地</div>
            <el-input style="width: 60%" v-model="formData.category_attribute[2].value" placeholder="请输入"></el-input>
          </div>
          <div style="width: 50%; display: flex">
            <div class="label" style="padding-top: 10px">保修期限</div>
            <el-input style="width: 60%" v-model="formData.category_attribute[3].value" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="container">
          <div style="width: 50%; display: flex">
            <div class="label" style="padding-top: 10px">商品类型</div>
            <el-input style="width: 60%" v-model="formData.category_attribute[4].value" placeholder="请输入"></el-input>
          </div>
          <div style="width: 50%; display: flex">
            <div class="label" style="padding-top: 10px">图案</div>
            <el-input style="width: 60%" v-model="formData.category_attribute[5].value" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="container">
          <div style="width: 50%; display: flex">
            <div class="label" style="padding-top: 10px">风格</div>
            <el-input style="width: 60%" v-model="formData.category_attribute[6].value" placeholder="请输入"></el-input>
          </div>
          <div style="width: 50%; display: flex">
            <div class="label" style="padding-top: 10px">特点</div>
            <el-input style="width: 60%" v-model="formData.category_attribute[7].value" placeholder="请输入"></el-input>
          </div>
        </div>
      </div>
      <!-- 销售资料 -->
      <div class="cell" style="margin-top: 20px">
        <div class="title" ref="title3">销售资料</div>
        <div v-if="btnShow" style="margin-top: 20px">
          <div class="container">
            <div class="label">规格</div>
            <div class="btn" @click="openStock">
              <span>
                <i class="el-icon-circle-plus-outline" style="font-size: 18px"></i>
                开启商品规格
              </span>
            </div>
          </div>
          <div class="container">
            <div class="label">*价格</div>
            <div style="width: 80%">
              <el-input type="number" @input="handleInput3(formData.salesInfo, 1)" style="width: 62.5%"
                v-model="formData.salesInfo.now_price" placeholder="请输入">
                <template slot="prepend">￥</template>
              </el-input>
            </div>
          </div>
          <div class="container">
            <div class="label">*商品数量</div>
            <div style="width: 80%">
              <el-input @input="handleInput3(formData.salesInfo, 2)" style="width: 62.5%" v-model="formData.salesInfo.num"
                placeholder="请输入">
              </el-input>
            </div>
          </div>
        </div>
        <div v-else style="margin-top: 20px">
          <div class="container">
            <div class="label">规格</div>
            <div class="btn-cell">
              <div style="display: flex">
                <div style="width: 10%; font-size: 14px; margin-right: 20px">
                  商品规格名称
                </div>
                <div style="width: 70%">
                  <el-input v-model="formData.salesInfo.spu[0]" placeholder="请输入规格名称，例如：颜色等" maxlength="14"
                    show-word-limit></el-input>
                </div>
              </div>
              <div id="list_item">
                <div style="display: flex; margin-top: 10px; align-items: center"
                  v-for="(item, i) in formData.salesInfo.spu_item.value" :key="i">
                  <div style="
                      width: 10%;
                      font-size: 14px;
                      margin-right: 20px;
                      line-height: 40px;
                    ">
                    选项{{ i + 1 }}
                  </div>
                  <div style="width: 70%">
                    <el-input v-model="item.value" placeholder="请输入规格名称，例如：白色等" maxlength="30" show-word-limit></el-input>
                  </div>
                  <i class="el-icon-rank sort" style="margin-left: 10px; cursor: pointer"></i>
                  <i v-if="i != 0" class="el-icon-delete" @click="delData(i)"
                    style="margin-left: 10px; cursor: pointer"></i>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px">
                <div style="
                    width: 10%;
                    font-size: 14px;
                    margin-right: 20px;
                    line-height: 40px;
                  "></div>
                <div style="width: 70%">
                  <div @click="addOptions" style="
                      width: 100%;
                      border: 1px dashed #1791f2;
                      color: #1791f2;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 40px;
                      font-size: 14px;
                      border-radius: 4px;
                      cursor: pointer;
                    ">
                    <span>
                      <i class="el-icon-circle-plus-outline" style="font-size: 18px"></i>
                      增加选项
                    </span>
                  </div>
                </div>
              </div>
              <div class="close-icon" @click="suReduction">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="label">规格二</div>
            <div class="btn-cell" v-if="stockShow">
              <div style="display: flex">
                <div style="width: 10%; font-size: 14px; margin-right: 20px">
                  商品规格名称
                </div>
                <div style="width: 70%">
                  <el-input v-model="formData.salesInfo.spu[1]" placeholder="请输入规格名称，例如：尺寸等" maxlength="14"
                    show-word-limit></el-input>
                </div>
              </div>
              <div id="list_items">
                <div style="display: flex; margin-top: 10px; align-items: center"
                  v-for="(item, i) in formData.salesInfo.spu_item.value2" :key="i">
                  <div style="
                      width: 10%;
                      font-size: 14px;
                      margin-right: 20px;
                      line-height: 40px;
                    ">
                    选项{{ i + 1 }}
                  </div>
                  <div style="width: 70%">
                    <el-input v-model="item.value" placeholder="请输入规格名称，例如：s,m,l等" maxlength="30"
                      show-word-limit></el-input>
                  </div>
                  <i class="el-icon-rank sort" style="margin-left: 10px; cursor: pointer"></i>
                  <i v-if="i != 0" class="el-icon-delete" @click="delData2(i)"
                    style="margin-left: 10px; cursor: pointer"></i>
                </div>
              </div>
              <div style="display: flex; margin-top: 10px">
                <div style="
                    width: 10%;
                    font-size: 14px;
                    margin-right: 20px;
                    line-height: 40px;
                  "></div>
                <div style="width: 70%">
                  <div @click="addOptions2" style="
                      width: 100%;
                      border: 1px dashed #1791f2;
                      color: #1791f2;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 40px;
                      font-size: 14px;
                      border-radius: 4px;
                      cursor: pointer;
                    ">
                    <span>
                      <i class="el-icon-circle-plus-outline" style="font-size: 18px"></i>
                      增加选项
                    </span>
                  </div>
                </div>
              </div>
              <div class="close-icon" @click="closeStock2">
                <i class="el-icon-close"></i>
              </div>
            </div>
            <div v-else class="btn" style="width: 60%" @click="openStock2">
              <span>
                <i class="el-icon-circle-plus-outline" style="font-size: 18px"></i>
                添加规格
              </span>
            </div>
          </div>
          <div class="container">
            <div class="label">规格资讯</div>
            <div class="contents">
              <span style="width: 75%; display: flex">
                <el-input type="number" @input="handleInput2(allSpecifications, 1)" style="width: 40%"
                  v-model="allSpecifications.price" placeholder="商品价格">
                  >
                  <template slot="prepend">￥</template>
                </el-input>
                <el-input @input="handleInput2(allSpecifications, 2)" style="width: 30%" v-model="allSpecifications.num"
                  placeholder="商品数量"></el-input>
                <el-input style="width: 30%" v-model="allSpecifications.artNum" @input="gghh(allSpecifications)"
                  placeholder="货号"></el-input>
              </span>
              <el-button type="primary" style="min-width: 23%; margin-left: 10px" @click="allEnable">全部套用</el-button>
            </div>
          </div>
          <div class="container">
            <div class="label">规格表</div>
            <div class="tab">
              <div class="tab-title">
                <div style="border-right: 1px solid #ebebeb">
                  {{
                    formData.salesInfo.spu[0]
                    ? formData.salesInfo.spu[0]
                    : " 规格名称"
                  }}
                </div>
                <div style="border-right: 1px solid #ebebeb" v-if="stockShow">
                  {{
                    formData.salesInfo.spu[1]
                    ? formData.salesInfo.spu[1]
                    : " 规格名称"
                  }}
                </div>
                <div style="border-right: 1px solid #ebebeb">商品价格</div>
                <div style="border-right: 1px solid #ebebeb">商品数量</div>
                <div>规格货号</div>
              </div>
              <div v-for="(item, i) in formData.salesInfo.spu_item.value" :key="i">
                <div class="tab-content">
                  <div style="
                      border-right: 1px solid #ebebeb;
                      width: 100%;
                      display: flex;
                      align-items: center;
                    ">
                    <el-input @input="ggjg(item)" :placeholder="'选项' + (i + 1)" v-model="item.value">
                    </el-input>
                  </div>
                  <div style="border-right: 1px solid #ebebeb; width: 100%" v-if="stockShow">
                    <div v-for="(val, i) in formData.salesInfo.spu_item.value2" :key="i" :style="{
                      'border-bottom': formData.salesInfo.spu_item.value2[
                        i + 1
                      ]
                        ? '1px solid #ebebeb'
                        : 'none',
                    }">
                      <el-input :placeholder="'选项' + (i + 1)" v-model="val.value">
                      </el-input>
                    </div>
                  </div>
                  <div style="border-right: 1px solid #ebebeb; width: 100%">
                    <div v-for="(val, i) in item.list" :key="i" :style="{
                      'border-bottom': item.list[i + 1]
                        ? '1px solid #ebebeb'
                        : 'none',
                    }">
                      <el-input type="number" @input="handleInput(val, 1)" placeholder="价格" v-model="val.now_price">
                        <template slot="prepend" style="width: 20%">￥</template>
                      </el-input>
                    </div>
                  </div>
                  <div style="border-right: 1px solid #ebebeb; width: 100%">
                    <div v-for="(val, i) in item.list" :key="i" :style="{
                      'border-bottom': item.list[i + 1]
                        ? '1px solid #ebebeb'
                        : 'none',
                    }">
                      <el-input placeholder="数量" @input="handleInput(val, 2)" v-model="val.stock">
                      </el-input>
                    </div>
                  </div>
                  <div style="width: 100%">
                    <div v-for="(val, i) in item.list" :key="i" :style="{
                      'border-bottom': item.list[i + 1]
                        ? '1px solid #ebebeb'
                        : 'none',
                    }">
                      <el-input @input="handleInput(val, 3)" placeholder="货号" v-model="val.sn">
                      </el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="label">规格图片</div>
            <div class="content">
              <div class="item">
                <UploadFiles1 :imageUrl="formData.salesInfo.spu_item_img_data"
                  :imgLength="formData.salesInfo.spu_item.value.length" :ids="formData.salesInfo.spu_item_img">
                </UploadFiles1>
                <div style="display: flex">
                  <span v-for="(item, i) in formData.salesInfo.spu_item.value" :key="i">
                    <div v-if="i <= formData.salesInfo.spu_item_img.length" class="name" style="
                        color: #999;
                        width: 80px;
                        text-align: center;
                        margin-right: 10px;
                      ">
                      {{ item.value }}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="container">
            <div class="label">尺码图表</div>
            <div class="content">
              <div class="item">
                <UploadFiles1
                  :imageUrl="formData.salesInfo.spu_item_imgs"
                ></UploadFiles1>
                <div class="name" style="color: #999">例子</div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 运费 -->
      <div class="cell" style="margin-top: 20px">
        <div class="title" ref="title4">运费</div>
        <div class="container">
          <div class="label">*重量</div>
          <div class="content" style="width: 60%">
            <el-input v-model="formData.freight.freight_weight" placeholder="请输入">
              <template slot="append">kg</template></el-input>
          </div>
        </div>
        <div class="container">
          <div class="label">包裹尺寸大小</div>
          <div class="contents" style="width: 60%">
            <el-input style="width: 33.33%; margin-right: 10px" v-model="formData.freight.size_width" placeholder="宽(整数)"
              @input="regKD(formData.freight)">
              <template slot="append">cm</template></el-input>
            <el-input style="width: 33.33%; margin-right: 10px" v-model="formData.freight.size_long" placeholder="长(整数)"
              @input="regCD(formData.freight)">
              <template slot="append">cm</template></el-input>
            <el-input style="width: 33.33%" v-model="formData.freight.size_high" placeholder="高(整数)"
              @input="regGD(formData.freight)">
              <template slot="append">cm</template></el-input>
          </div>
        </div>
        <div class="container">
          <div class="label">运费</div>
          <div class="content" style="width: 60%">
            <el-input @input="regYF(formData.freight)" type="number" v-model="formData.freight.freight_free"
              placeholder="请输入">
              <template slot="append">RM</template></el-input>
          </div>
        </div>
      </div>
      <!-- 其他 -->
      <div class="cell" style="margin-top: 20px">
        <div class="title" ref="title5">其他</div>
        <div class="container">
          <div class="label">*发货时间</div>
          <div class="content" style="line-height: 40px; font-size: 14px; color: #999">
            我需要
            <el-input v-model="formData.other.delivery" style="width: 10%; margin: 0 10px" placeholder="0">
            </el-input>
            出货天数（您可以填写3,5到10）
          </div>
        </div>
        <div class="container">
          <div class="label">商品保存状况</div>
          <div class="contents" style="width: 60%">
            <el-select style="width: 100%" v-model="formData.other.goods_condition" placeholder="请选择">
              <el-option label="全新" :value="0"> </el-option>
              <el-option label="二手" :value="1"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="container">
          <div class="label">全球商品货号</div>
          <div class="content" style="width: 60%">
            <el-input v-model="formData.other.goods_sn" placeholder="请输入">
            </el-input>
          </div>
        </div>
      </div>
      <div class="cell-bottom">
        <el-button @click="toGo" style="min-width: 140px" size="small" plain>取消</el-button>

        <span v-if="addgoodsType == 2">
          <el-button style="min-width: 140px" size="small" type="primary" @click="addGoods(0)">修改</el-button>
          <el-button style="min-width: 140px" size="small" type="primary" @click="addGoods(1)">修改并发布</el-button></span>
        <span v-else>
          <el-button style="min-width: 140px; margin-left: 10px" size="small" type="primary"
            @click="addGoods(3)">修改</el-button></span>
      </div>
    </div>
    <div class="right">
      <div class="cell">
        <div class="left">
          <div class="progress" :style="{ height: wdHeight + '%' }"></div>
        </div>
        <div class="right">
          <div class="item" @click="scroll(1)">基本资讯</div>
          <div class="item" @click="scroll(2)">商品属性</div>
          <div class="item" @click="scroll(3)">销售资料</div>
          <div class="item" @click="scroll(4)">运费</div>
          <div class="item" @click="scroll(5)">其他</div>
        </div>
      </div>
    </div>

    <el-dialog title="编辑类目" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <div style="margin-top:-40px" key="1">
        <AddGoodsCategory @setFl="setFl" @handleClose="handleClose" :info="formData.basicInfo">
        </AddGoodsCategory>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import UploadFiles1 from "@/components/UploadFiles1.vue";
import UploadVideo1 from "@/components/UploadVideo1.vue";
import { operatorGoods, goodsFind, subGoodsCommon } from "@/api/shopGoods.js";
import { brandList } from "@/api/common.js";
import Sortable from "sortablejs";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import env from "@/settings/env.js";
import { getSAuth, getSchool } from "@/utils/auth.js";
import AddGoodsCategory from '@/components/category/AddGoodsCategory'

export default {
  components: {
    UploadFiles1,
    // Editor,
    // Toolbar,
    UploadVideo1,
    AddGoodsCategory
  },
  data() {
    return {
      dialogVisible: false,
      baseURL: env.apiUrlPrefix,
      btnShow: true,
      stockShow: false,
      input1: "",
      accept: ".mp4",
      formData: {
        basicInfo: {
          //基本资讯
          imageUrl1: [],
          ids1: [],
          imageUrl2: [[], [], [], [], [], [], [], []],
          ids2: [[], [], [], [], [], [], [], []],
          goods_video: [],
          goods_video_data: [],
          goods_video_cover: [],
          title: "",
          description: "",
          category_path: "",
          category_path_id: "",
        },
        category_attribute: [
          //商品属性
          {
            name: "品牌",
            value: "",
          },
          {
            name: "材质",
            value: "",
          },
          {
            name: "原产地",
            value: "",
          },
          {
            name: "保修期限",
            value: "",
          },
          {
            name: "商品类型",
            value: "",
          },
          {
            name: "图案",
            value: "",
          },
          {
            name: "风格",
            value: "",
          },
          {
            name: "特点",
            value: "",
          },
        ],
        salesInfo: {
          //销售资料
          num: "",
          now_price: "",
          spu: [""],
          spu_item: {
            value: [
              {
                value: "",
                list: [
                  {
                    now_price: "",
                    stock: "",
                    sn: "",
                  },
                ],
              },
            ],
            value2: [],
            now_price: [{ value: "" }],
            stock: [{ value: "" }],
            sn: [{ value: "" }],
          },
          spu_item_img: [],
          spu_item_img_data: [],
        },
        freight: {
          //运费
          freight_free: "",
          freight_weight: "",
          size_width: "",
          size_long: "",
          size_high: "",
        },
        other: {
          //其他
          delivery: "",
          goods_condition: 0,
          goods_sn: "",
        },
      },
      wdHeight: 12,
      allSpecifications: {
        price: "",
        num: "",
        artNum: "",
      },
      options: [],

      editor: null,
      html: "",
      toolbarConfig: { excludeKeys: ["emotion", "todo"] }, //富文本工具栏配置
      editorConfig: {
        // 富文本菜单栏配置
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            server: env.apiUrlPrefix + "api/upload/files",
            fieldName: "imagelist",
            headers: {
              authorization: getSAuth(),
              school: getSchool(),
            },
            customInsert(res, insertFn) {
              // res 即服务端的返回结果

              // 从 res 中找到 url alt href ，然后插图图片
              insertFn(res.data.url);
            },
          },
          uploadVideo: {
            server: env.apiUrlPrefix + "api/upload/video",
            fieldName: "imagelist",
            // 单个文件的最大体积限制，默认为 10M
            maxFileSize: 30 * 1024 * 1024,
            headers: {
              authorization: getSAuth(),
              school: getSchool(),
            },
            customInsert: this.customInsertVideo,
            // onBeforeUpload: this.onBeforeUploadVideo,
            onError: this.onErrorVideo,
          },
        },
      },
      mode: "default", // or 'simple'
      addgoodsType: 1,
    };
  },
  created() {
    if (sessionStorage.ADD_GOODS_TYPE) {
      this.addgoodsType = sessionStorage.ADD_GOODS_TYPE;

      this.$router.options.routes.forEach((item) => {
        if (item.name == "myGoodsz") {
          item.children.forEach((val) => {
            if (val.name == "editGoodsInfo") {
              val.meta.bread[1].name =
                this.addgoodsType == 1 ? "全球商品" : "我的商品";
              val.meta.bread[1].path =
                this.addgoodsType == 1 ? "globalGoods" : "myGoods";
            }
          });
        }
      });
    }
  },
  mounted() {
    this.getGoodsInfo();
    this.gundong();
    this.getBrandList();
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    customInsertVideo(res, insertFn) {
      // res 即服务端的返回结果

      // 从 res 中找到 url alt href ，然后插图图片
      insertFn(res.data.url);
    },

    onErrorVideo() {
      this.$message.error("文件大小超过限制，最大30Mb");
    },
    getGoodsInfo() {
      goodsFind({
        goods_id: sessionStorage.GOODSID,
        type: sessionStorage.ADD_GOODS_TYPE == 1 ? 2 : 1,
      }).then((res) => {
        if (res.code == 1) {

          // 基本资讯
          if (res.data.goodsInfo.image) {
            this.formData.basicInfo.imageUrl1.push(res.data.goodsInfo.image);
            this.formData.basicInfo.ids1.push(res.data.goodsInfo.attachment_id);
          }

          let img = res.data.goodsInfo.carousel_image.split(",");
          let img1 = res.data.goodsInfo.attachment_ids.split(",");
          img.forEach((item, i) => {
            if (item != "") {
              this.formData.basicInfo.imageUrl2[i].push(item);
            }
          });
          img1.forEach((item, i) => {
            if (item != "") {
              this.formData.basicInfo.ids2[i].push(item);
            }
          });
          if (
            "goods_video" in res.data.goodsInfo &&
            res.data.goodsInfo.goods_video
          ) {
            this.formData.basicInfo.goods_video.push(
              res.data.goodsInfo.attachment_video_id
            );
            this.formData.basicInfo.goods_video_data.push(
              res.data.goodsInfo.goods_video
            );
            this.formData.basicInfo.goods_video_cover.push(
              res.data.goodsInfo.goods_video_cover
            );
          }

          this.formData.basicInfo.title = res.data.goodsInfo.title;
          this.formData.basicInfo.description = res.data.goodsInfo.description;
          this.formData.basicInfo.category_path =
            res.data.goodsInfo.category_name;
          this.formData.basicInfo.title = res.data.goodsInfo.title;
          this.formData.basicInfo.category_path =
            res.data.goodsInfo.category_name;
          this.formData.basicInfo.category_path_id =
            res.data.goodsInfo.category_path_id;
          //   商品属性
          this.formData.category_attribute = res.data.attribute;
          // this.formData.category_attribute[0].value = this.formData
          //   .category_attribute[0].value
          //   ? Number(this.formData.category_attribute[0].value)
          //   : this.formData.category_attribute[0].value;
          //   销售资料
          if (res.data.spu.length > 0) {
            this.openStock();
            this.formData.salesInfo.spu = [];
            this.formData.salesInfo.spu = res.data.spu;
            this.formData.salesInfo.spu_item.value = [];
            let obj = JSON.parse(JSON.stringify(res.data.skuItem))

            Object.keys(obj).forEach((item) => {
              this.formData.salesInfo.spu_item.value.push({
                value: item,
                list: obj[item],
              });
            });
            if (res.data.spuItem[1]) {
              this.openStock2();
              this.formData.salesInfo.spu.pop();
              this.formData.salesInfo.spu_item.value2 = [];
              res.data.spuItem[1].forEach((item) => {
                this.formData.salesInfo.spu_item.value2.push({ value: item });
              });
            }
            if (res.data.spuItemImg.length != 0) {
              let arr1 = [];
              let arr2 = [];
              res.data.spuItemImg[0].forEach((item) => {
                arr1.push(item.id);
                arr2.push(item.url);
              });
              this.formData.salesInfo.spu_item_img = arr1.splice(",");
              this.formData.salesInfo.spu_item_img_data = arr2.splice(",");
            }
          } else {
            this.formData.salesInfo.now_price = res.data.goodsInfo.price;
            this.formData.salesInfo.num = res.data.goodsInfo.stock;
          }
          //   运费
          this.formData.freight["freight_free"] =
            res.data.goodsInfo.freight_free;
          this.formData.freight["freight_weight"] =
            res.data.goodsInfo.freight_weight;
          this.formData.freight["size_high"] = res.data.goodsInfo.size_high;
          this.formData.freight["size_long"] = res.data.goodsInfo.size_long;
          this.formData.freight["size_width"] = res.data.goodsInfo.size_width;

          //   其他
          this.formData.other.delivery = res.data.goodsInfo.delivery;
          this.formData.other.goods_condition =
            res.data.goodsInfo.goods_condition;
          this.formData.other.goods_sn = res.data.goodsInfo.goods_sn;
        }
      });
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },

    getBrandList() {
      brandList().then((res) => {
        if (res.code == 1) {
          this.options = res.data;
        }
      });
    },
    // 右侧跳转
    scroll(index) {
      let box = document.getElementsByClassName("layShopCenter");
      let distance = 0;
      if (index == 1) {
        distance = this.$refs.title1.offsetTop - 50;
      }
      if (index == 2) {
        distance = this.$refs.title2.offsetTop - 40;
      }
      if (index == 3) {
        distance = this.$refs.title3.offsetTop - 40;
      }
      if (index == 4) {
        distance = this.$refs.title4.offsetTop;
      }
      if (index == 5) {
        distance = this.$refs.title5.offsetTop;
      }
      box[0].scrollTo(0, distance);
    },
    // 滚动事件
    gundong() {
      let _this = this;
      let box = document.getElementsByClassName("layShopCenter");

      this.$nextTick(() => {
        box[0].onscroll = function () {
          if (
            box[0].scrollTop >= _this.$refs.title1.offsetTop + 500 ||
            box[0].scrollTop == 0
          ) {
            _this.wdHeight = 12;
          }
          if (box[0].scrollTop >= _this.$refs.title2.offsetTop - 200) {
            _this.wdHeight = 32;
          }
          if (box[0].scrollTop >= _this.$refs.title3.offsetTop - 100) {
            _this.wdHeight = 56;
          }
          if (box[0].scrollTop >= _this.$refs.title4.offsetTop + 500) {
            _this.wdHeight = 78;
          }
          if (box[0].scrollTop >= _this.$refs.title5.offsetTop - 500) {
            _this.wdHeight = 100;
          }
        };
      });
    },
    // 规格一增加选项
    addOptions() {
      this.formData.salesInfo.spu_item.value.push(
        this.stockShow
          ? {
            value: "",
            list: [],
          }
          : {
            value: "",
            list: [
              {
                now_price: "",
                stock: "",
                sn: "",
              },
            ],
          }
      );
      this.formData.salesInfo.spu_item.value2.forEach(() => {
        this.formData.salesInfo.spu_item.value[
          this.formData.salesInfo.spu_item.value.length - 1
        ].list.push({
          now_price: "",
          stock: "",
          sn: "",
        });
      });
    },
    // 规格2增加选项
    addOptions2() {
      this.formData.salesInfo.spu_item.value2.push({
        value: "",
      });
      this.formData.salesInfo.spu_item.value.forEach((item) => {
        item.list.push({
          now_price: "",
          stock: "",
          sn: "",
        });
      });
    },
    // 全部套用
    allEnable() {
      this.formData.salesInfo.spu_item.value.forEach((item) => {
        item.list.forEach((val) => {
          val.now_price = this.allSpecifications.price;
          val.stock = this.allSpecifications.num;
          val.sn = this.allSpecifications.artNum;
        });
      });
    },
    // 关闭所有规格
    suReduction() {
      this.btnShow = true;
      this.stockShow = false;
      this.formData.salesInfo.spu = [""];
      (this.formData.salesInfo.spu_item = {
        value: [
          {
            value: "",
            list: [
              {
                now_price: "",
                stock: "",
                sn: "",
              },
            ],
          },
        ],
        value2: [],
        now_price: [{ value: "" }],
        stock: [{ value: "" }],
        sn: [{ value: "" }],
      }),
        (this.allSpecifications = {
          price: "",
          num: "",
          artNum: "",
        });
      this.formData.salesInfo.spu_item_img = [];
      this.formData.salesInfo.spu_item_img_data = [];
    },
    // 关闭规格二
    closeStock2() {
      this.stockShow = false;
      this.formData.salesInfo.spu.pop();
      this.formData.salesInfo.spu_item.value2 = [];
      this.formData.salesInfo.spu_item.value.forEach((item) => {
        item.list.splice(1);
      });
    },
    // 添加商品
    addGoods(status) {
      if (this.formData.basicInfo.imageUrl1.length == 0) {
        this.$message.error("商品封面不能为空");
        return false;
      }
      if (this.formData.basicInfo.description == "<p><br></p>") {
        this.$message.error("商品描述不能为空");
        return false;
      }
      if (this.formData.category_attribute[0].value == "") {
        this.$message.error("商品品牌不能为空");
        return false;
      }
      if (this.formData.category_attribute[1].value == "") {
        this.$message.error("商品材质不能为空");
        return false;
      }
      let _this = this;
      let arr = [];
      this.formData.basicInfo.ids2.forEach((item) => {
        if (item[0]) {
          arr.push(item[0]);
        }
      });
      let data = {
        image: this.formData.basicInfo.ids1[0], //封面图
        carousel_image: arr.join(","), //轮播图
        goods_video: this.formData.basicInfo.goods_video[0]
          ? this.formData.basicInfo.goods_video[0]
          : "", //封面视频
        goods_video_cover: this.formData.basicInfo.goods_video_cover[0]
          ? this.formData.basicInfo.goods_video_cover[0]
          : "",
        title: this.formData.basicInfo.title, //标题
        description: this.formData.basicInfo.description, //描述
        category_path_id: this.formData.basicInfo.category_path_id, //类目id
      };
      let ggz = "";
      if (!this.btnShow) {
        ggz = {
          spu: this.formData.salesInfo.spu.join(","),
          spu_item: {
            0: "",
          },
          sku: {},
          market_price: {},
          now_price: {},
          stock: {},
          sn: {},
          spu_item_img: {
            0: this.formData.salesInfo.spu_item_img[0]
              ? this.formData.salesInfo.spu_item_img.join(",")
              : "",
            1: "",
          },
          price: this.formData.salesInfo.now_price
            ? this.formData.salesInfo.now_price
            : this.formData.salesInfo.spu_item.value[0].list[0].now_price,
          num: this.formData.salesInfo.num,
        };
        ggz.spu_item = {
          0: "",
          1: "",
        };

        let key1 = 0;
        let key2 = 0;
        this.formData.salesInfo.spu_item.value.forEach((item) => {
          ggz.spu_item[0] =
            ggz.spu_item[0] && item.value
              ? ggz.spu_item[0] + "," + item.value
              : ggz.spu_item[0] + item.value;
          item.list.forEach((val) => {
            ggz.market_price[key2] = val.now_price;
            ggz.now_price[key2] = val.now_price;
            ggz.stock[key2] = val.stock;
            ggz.sn[key2] = val.sn;
            // ggz.num+=Number(val.stock)
            key2 += 1;
          });
          if (this.stockShow) {
            this.formData.salesInfo.spu_item.value2.forEach((val) => {
              ggz.sku[key1] = item.value + "," + val.value;
              key1 += 1;
            });
          } else {
            ggz.sku[key1] = item.value;
            key1 += 1;
          }
        });
        this.formData.salesInfo.spu_item.value2.forEach((item) => {
          ggz.spu_item[1] =
            ggz.spu_item[1] && item.value
              ? ggz.spu_item[1] + "," + item.value
              : ggz.spu_item[1] + item.value;
        });
      } else {
        ggz = {
          spu: "",
          spu_item: "",
          sku: "",
          market_price: "",
          now_price: "",
          stock: "",
          sn: "",
          spu_item_img: "",
          price: this.formData.salesInfo.now_price,
          num: this.formData.salesInfo.num,
        };
      }

      // if (this.stockShow) {

      // }
      if (this.addgoodsType == 2) {
        operatorGoods({
          goods_id: sessionStorage.GOODSID,
          brand_id: 1,
          ...data,
          ...ggz,
          ...this.formData.other, //其他
          ...this.formData.freight, //运费
          category_attribute: this.formData.category_attribute, //属性
          status: status,
        }).then((res) => {
          if (res.code == 1) {
            _this
              .$confirm("商品修改成功", "提示", {
                confirmButtonText: "返回查看",
                //   cancelButtonText: "返回查看",
                type: "success",
              })
              .then(() => {
                _this.$router.replace("myGoods");
              })
              .catch(() => {
                _this.$router.replace("myGoods");
              });
          }
        });
      } else {
        subGoodsCommon({
          goods_id: sessionStorage.GOODSID,
          brand_id: 1,
          ...data,
          ...ggz,
          ...this.formData.other, //其他
          ...this.formData.freight, //运费
          category_attribute: this.formData.category_attribute, //属性
          status: 0,
        }).then((res) => {
          if (res.code == 1) {
            _this
              .$confirm("商品修改成功", "提示", {
                // confirmButtonText: "继续添加",
                cancelButtonText: "返回查看",
                type: "success",
              })
              .then(() => {
                _this.$router.replace("globalGoods");
              })
              .catch(() => {
                _this.$router.replace("globalGoods");
              });
          }
        });
      }
    },
    openStock() {
      this.btnShow = false;
      this.formData.salesInfo.now_price = "";
      this.formData.salesInfo.num = "";
      this.$nextTick(() => {
        //这里是获取到数组的元素
        let el = document.getElementById("list_item");
        new Sortable(el, {
          handle: ".sort", // handle's class
          animation: 150,
          onEnd: (evt) => {
            //拖拽动作结束后执行
            this.formData.salesInfo.spu_item.value.splice(
              evt.newIndex,
              0,
              this.formData.salesInfo.spu_item.value.splice(evt.oldIndex, 1)[0]
            );
            let newArray = this.formData.salesInfo.spu_item.value.slice(0);
            this.formData.salesInfo.spu_item.value = [];
            this.$nextTick(function () {
              this.formData.salesInfo.spu_item.value = newArray;
            });
          },
        });
      });
    },
    // 打开规格二
    openStock2() {
      this.stockShow = true;
      this.formData.salesInfo.spu.push("");
      this.formData.salesInfo.spu_item.value2.push({
        value: "",
      });
      this.$nextTick(() => {
        let el = document.getElementById("list_items");
        new Sortable(el, {
          handle: ".sort", // handle's class
          animation: 150,
          onEnd: (evt) => {
            //拖拽动作结束后执行
            this.formData.salesInfo.spu_item.value2.splice(
              evt.newIndex,
              0,
              this.formData.salesInfo.spu_item.value2.splice(evt.oldIndex, 1)[0]
            );
            let newArray = this.formData.salesInfo.spu_item.value2.slice(0);
            this.formData.salesInfo.spu_item.value2 = [];
            this.$nextTick(function () {
              this.formData.salesInfo.spu_item.value2 = newArray;
            });
          },
        });
      });
    },
    // 删除数据
    delData(index) {
      this.formData.salesInfo.spu_item.value.splice(index, 1);
    },
    // 规格二删除数据
    delData2(index) {
      this.formData.salesInfo.spu_item.value2.splice(index, 1);
      this.formData.salesInfo.spu_item.value.forEach((item) => {
        item.list.splice(index, 1);
      });
    },
    toGo() {
      this.$router.go(-1);
    },
    handleInput(value, type) {
      if (type == 1) {
        if (
          (value.now_price == 0 && value.now_price != "") ||
          value.now_price[0] == "."
        ) {
          this.$message.warning("商品价格最低为单位1￥");
          value.now_price = "1";
          this.$forceUpdate();
        }

        if (value.now_price.indexOf(".") != -1) {
          let str_ = value.now_price.substr(value.now_price.indexOf(".") + 1);
          if (str_.indexOf(".") != -1) {
            value.now_price = value.now_price.substr(
              0,
              value.now_price.indexOf(".") + str_.indexOf(".") + 1
            );
          }
          if (str_.length > 2) {
            // this.$message.warning(`金额小数点后只能输入两位，请正确输入！`);
            return (value.now_price = value.now_price.slice(0, -1));
          }
        }
      }
      if (type == 2) {
        let reg = /^[1-9]\d*$/;
        if (!reg.test(value.stock)) {
          value.stock = value.stock.splice(0, -1);
        }
      }
      if (type == 3) {
        let reg = /^[A-Za-z0-9]+$/;
        if (!reg.test(value.sn)) {
          value.sn = value.sn.slice(0, -1);
        }
        if (!/^[u4e00-u9fa5]*$/.test(value.sn)) {
          value.sn = "";
        }
      }
    },
    handleInput2(value, type) {
      if (type == 1) {
        if ((value.price == 0 && value.price != "") || value.price[0] == ".") {
          this.$message.warning("商品价格最低为单位1￥");
          value.price = "1";
          this.$forceUpdate();
        }

        if (value.price.indexOf(".") != -1) {
          let str_ = value.price.substr(value.price.indexOf(".") + 1);
          if (str_.indexOf(".") != -1) {
            value.price = value.price.substr(
              0,
              value.price.indexOf(".") + str_.indexOf(".") + 1
            );
          }
          if (str_.length > 2) {
            // this.$message.warning(`金额小数点后只能输入两位，请正确输入！`);
            return (value.price = value.price.slice(0, -1));
          }
        }
      } else {
        let reg = /^[1-9]\d*$/;
        if (!reg.test(value.num)) {
          value.num = value.num.slice(0, -1);
        }
      }
    },
    handleInput3(value, type) {
      if (type == 1) {
        if (value.now_price == 0 && value.now_price != "") {
          this.$message.info("商品价格最低为单位1￥");
          value.now_price = "1";
          this.$forceUpdate();
        }
        if (value.now_price.indexOf(".") != -1) {
          let str_ = value.now_price.substr(value.now_price.indexOf(".") + 1);
          if (str_.indexOf(".") != -1) {
            value.now_price = value.now_price.substr(
              0,
              value.now_price.indexOf(".") + str_.indexOf(".") + 1
            );
          }
          if (str_.length > 2) {
            // this.$message.warning(`金额小数点后只能输入两位，请正确输入！`);
            return (value.now_price = value.now_price.slice(0, -1));
          }
        }
      } else {
        let reg = /^[1-9]\d*$/;
        if (!reg.test(value.num)) {
          value.num = value.num.slice(0, -1);
        }
      }
    },
    gghh(val) {
      let reg = /^[A-Za-z0-9]+$/;
      if (!reg.test(val.artNum)) {
        val.artNum = val.artNum.slice(0, -1);
      }
      if (!/^[u4e00-u9fa5]*$/.test(val.artNum)) {
        val.artNum = "";
      }
    },
    regYF(val) {
      if (val.freight_free.indexOf(".") != -1) {
        let str_ = val.freight_free.substr(val.freight_free.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          val.freight_free = val.freight_free.substr(
            0,
            val.freight_free.indexOf(".") + str_.indexOf(".") + 1
          );
        }
        if (str_.length > 2) {
          // this.$message.warning(`金额小数点后只能输入两位，请正确输入！`);
          return (val.freight_free = val.freight_free.slice(0, -1));
        }
      }
    },
    regKD(val) {
      let reg = /^[1-9]\d*$/;
      if (!reg.test(val.size_width)) {
        val.size_width = val.size_width.slice(0, -1);
      }
    },
    regCD(val) {
      let reg = /^[1-9]\d*$/;
      if (!reg.test(val.size_long)) {
        val.size_long = val.size_long.slice(0, -1);
      }
    },
    regGD(val) {
      let reg = /^[1-9]\d*$/;
      if (!reg.test(val.size_high)) {
        val.size_high = val.size_high.slice(0, -1);
      }
    },
    handleClose() {
      this.dialogVisible = false
    },

    setFl(obj) {
      this.formData.basicInfo.category_path = obj.areChoosing;
      this.formData.basicInfo.category_path_id = obj.categoryData;
      this.dialogVisible = false
    },

    handleOpenPop() {
      this.dialogVisible = true
    }
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" >
.addGoodsInfo {
  padding-top: 20px;
  padding-bottom: 50px;
  width: 80%;
  margin: 0 auto;
  display: flex;

  a {
    color: blue !important;
    text-decoration: underline;
  }

  .left {
    width: 75%;

    .cell {
      background: #fff;
      width: 100%;
      padding: 30px;
      box-shadow: 0 1px 4px 0 rgba(220, 220, 220, 0.6);

      .container {
        display: flex;
        margin-bottom: 30px;

        .btn {
          width: 50%;
          height: 40px;
          border: 1px dashed #1791f2;
          color: #1791f2;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          border-radius: 4px;
          cursor: pointer;
        }

        .btn-cell {
          width: 60%;
          padding: 20px;
          background: #fafafa;
          color: #999;
          text-align: right;
          position: relative;

          .close-icon {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
          }
        }

        .label {
          width: 15%;
          text-align: right;
          font-size: 14px;
          color: #666666;
          padding-right: 30px;
          //   padding-top: 20px;
          line-height: 40px;
        }

        .content {
          width: 80%;
          display: flex;
          flex-wrap: wrap;

          .item {
            margin-right: 20px;
            margin-bottom: 20px;
          }
        }

        .contents {
          display: flex;
          align-items: center;
          width: 80%;
        }

        .text {
          font-size: 14px;
          color: #999999;
          line-height: 20px;
        }

        .name {
          font-size: 14px;
          text-align: center;
        }

        .names {
          color: #999999;
        }

        .tab {
          border: 1px solid #ebebeb;
          border-bottom: none;
          width: 80%;

          .tab-title {
            font-size: 14px;
            color: #999999;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ebebeb;
            background: #fafafa;

            div {
              width: 25%;
              text-align: center;
              line-height: 40px;
            }
          }

          .tab-content {
            display: flex;
            width: 100%;
            border-bottom: 1px solid #ebebeb;

            div {

              // width: 100%;
              .el-input__inner {
                border-color: transparent;
                text-align: center;
                color: #999;
              }

              .el-input-group__append,
              .el-input-group__prepend {
                border-color: transparent;
                border-right: 1px solid #ebebeb;
              }
            }
          }
        }
      }

      .title {
        font-size: 18px;
      }
    }

    .cell1 {
      background: #fff;
      width: 100%;
      padding: 30px;
      margin-top: 20px;
      box-shadow: 0 1px 4px 0 rgba(220, 220, 220, 0.6);

      .container {
        display: flex;
        margin-bottom: 30px;

        .label {
          width: 30%;
          text-align: right;
          font-size: 14px;
          color: #666666;
          padding-right: 30px;
          padding-top: 20px;
        }

        .content {
          width: 80%;
          display: flex;
          flex-wrap: wrap;

          .item {
            margin-right: 20px;
            margin-bottom: 20px;
          }
        }

        .text {
          font-size: 14px;
          color: #999999;
          line-height: 20px;
        }

        .name {
          font-size: 14px;
          text-align: center;
        }

        .names {
          color: #999999;
        }
      }

      .title {
        font-size: 18px;
      }
    }

    .cell-bottom {
      display: flex;
      width: 100%;
      justify-content: right;
      margin-top: 20px;
    }
  }

  .right {
    font-size: 14px;
    margin-left: 30px;

    .cell {
      display: flex;
      padding-top: 40px;
      position: fixed;

      .left {
        width: 2px;
        height: 138px;
        background: #dbdbdb;
        position: relative;

        .progress {
          width: 100%;
          // height: 28px;
          position: absolute;
          top: 0;
          background: #ee4d2d;
        }
      }

      .right .item {
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>